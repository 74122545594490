@import url("https://use.typekit.net/upn3ebc.css");

$sigFont: objektiv-mk2, sans-serif;
$signatureFont: $sigFont;
$sigFontWide: objektiv-mk2, sans-serif;
$deco: objektiv-mk2, sans-serif;
$white: #fff;
$black: #000;
// $red: #a32135;
$red:  #000;
$yellow:  #e98409;
$darkRed: #6f1624;
$orange: black;
$darkOrange: #444;
$pink:  $orange;
// $background-color: #fffaec;
$background-color: #f1f2f3;
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

$max-site-width: 840px;
