@import './_vars.scss';

.form-wrapper-contact {
  max-width: 900px;
  margin: 1em auto;
  text-align: left;
  line-height: 1.6em;
  // background: rgba(255,255,255,0.5);
  border: 10px solid $orange;
  form {

    h2 {
      font-size: 1.5em;
      margin: 1em 0;
      font-family: $deco;
      font-weight: 800;
      // text-transform: lowercase;
    }
    padding: 1em;
    // color: black;

    p {
      // font-family: Helvetica;
    }
    button {
      background: $orange;
      color: white;
      border: none;
      box-shadow: none;
      padding: 0.5em;
      width: 10em;
    }
  }
}
